import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import explore1Svg from "images/collections/explore1.svg";
import explore2Svg from "images/collections/explore2.svg";
import explore3Svg from "images/collections/explore3.svg";
import explore1Png from "images/collections/explore1.png";
import explore2Png from "images/collections/explore2.png";
import explore3Png from "images/collections/explore3.png";
import VoucherDataCards from "./VoucherDataCard";
import Heading2 from "components/Heading/Heading2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Popover, Transition } from "@headlessui/react";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import { Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import DynamicTable from "components/CustomTable";
import Pagination from "shared/Pagination/Pagination";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { hrListVoucherByVoucherType } from "network/services/HR";
import toast from "react-hot-toast";

interface VoucherRecipient {
  slno: string;
  id: string;
  name: string;
  email: string;
  isClaimed: string | "false";
  claimedDate: string;
  orderNo: any;
  orderStatus: string;
  trackingLink: string;
  latestUpdatedOn: string;
  daysOfVocuherCreation: string;

}

const convertToExcel = (data: VoucherRecipient[], filename: any) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(excelBlob, filename);
};


const VoucherDetails = ({
  gridClassName = "grid-cols-1 md:grid-cols-2 xl:grid-cols-4",
}) => {
  const [VOUCHERS_REGISTERED_DATA, SET_VOUCHERS_REGISTERED_DATA] = useState<string>("");
  const [VOUCHERS_CLAIMED_DATA, SET_VOUCHERS_CLAIMED_DATA] = useState<string>("");
  const [VOUCHERS_YET_TO_CLAIM_DATA, SET_VOUCHERS_YET_TO_CLAIM_DATA] = useState<string>("");
  const [VOUCHERS_GOT_DELIVERED_DATA, SET_VOUCHERS_GOT_DELIVERED_DATA] = useState<string>("");

  let data = [
    {
      id: 1,
      name: VOUCHERS_REGISTERED_DATA,
      desc: "Voucher Generated",
      image: explore1Png,
      svgBg: explore1Svg,
      color: "bg-indigo-50",
      path: "/hr-vouchers",
    },
    {
      id: 2,
      name: VOUCHERS_CLAIMED_DATA,
      desc: "Voucher Claimed",
      image: explore2Png,
      svgBg: explore2Svg,
      color: "bg-slate-100/80",
      path: "/hr-vouchers",
    },
    {
      id: 3,
      name: VOUCHERS_YET_TO_CLAIM_DATA,
      desc: "Voucher Pending for Redemption",
      image: explore3Png,
      svgBg: explore3Svg,
      color: "bg-violet-50",
      path: "/hr-vouchers",
    },
    {
      id: 4,
      name: VOUCHERS_GOT_DELIVERED_DATA,
      desc: "Order Delivered",
      image: explore3Png,
      svgBg: explore3Svg,
      color: "bg-violet-50",
      path: "/hr-vouchers",
    },
  ];

  const location = useLocation();
  const employeeData = location.state?.rowData;
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const voucherData = location.state?.rowData;

  const [searchQuery, setSearchQuery] = useState("");

  const [tableData, setTableData] = useState<VoucherRecipient[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<VoucherRecipient[]>([]);


  const filterTableData = (inputQuery: any) => {
    const lowerCaseQuery = inputQuery.toLowerCase();
    const filteredData = tableData.filter(
      (item) =>
        item.name.toLowerCase().includes(lowerCaseQuery) ||
        item.email.toLowerCase().includes(lowerCaseQuery) ||
        item.orderNo.toLowerCase().includes(lowerCaseQuery) ||
        item.orderStatus.toLowerCase().includes(lowerCaseQuery) ||
        item.trackingLink.toLowerCase().includes(lowerCaseQuery) ||
        item.daysOfVocuherCreation

      // Add more fields if needed
    );
    setFilteredTableData(filteredData);
  };

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
    filterTableData(e.target.value);
  };

  const [tableHeaders, setTableHeader] = useState([
    { name: "Slno", key: "slno" },
    { name: "Name", key: "name" },
    { name: "Email", key: "email" },
    { name: "Claimed ?", key: "isClaimed" },
    { name: "Claimed Date", key: "claimedDate" },
    { name: "Order No", key: "orderNo" },
    { name: "Status", key: "orderStatus" },
    { name: "Tracking Link", key: "trackingLink" },
    { name: "Days Since Voucher Created", key: "daysOfVocuherCreation" },
    //{ name: "Updated on", key: "latestUpdatedOn" }
  ]);


  const ConvertDateObjToHumanReadableDateString = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }

  const getDaysSinceCreation = (createdAt: string): number => {
    const createdAtDate = new Date(createdAt);
    const today = new Date();
    const timeDiff = Math.abs(today.getTime() - createdAtDate.getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24)); // Convert milliseconds to days
  };

  const getVouchersByVoucherType = async () => {
    try {
      const resp = await hrListVoucherByVoucherType(id!);
      console.log(resp.data, "Console.log data");
      if (resp?.data) {
        let count = 1;
        const userData: VoucherRecipient[] = resp.data.map((voucherRecipient: any) => ({
          slno: count++, // Increment count for each voucher recipient
          name: voucherRecipient.name,
          daysOfVocuherCreation: getDaysSinceCreation(voucherRecipient.createdAt),
          email: voucherRecipient.email,
          isClaimed: voucherRecipient?.isClaimed.toString(), // Initialize as undefined since it's not provided in the data
          claimedDate: voucherRecipient.claimedDate !== undefined ? ConvertDateObjToHumanReadableDateString(voucherRecipient.claimedDate) : "Not yet", // Initialize as undefined since it's not provided in the data
          orderNo: voucherRecipient.order?.orderNo !== undefined ? `#${voucherRecipient.order?.orderNo.toString()}` : "Not yet", // You need to fill this with appropriate data if available
          orderStatus: voucherRecipient?.order?.orderStatus[voucherRecipient?.order?.orderStatus.length - 1]?.status !== undefined ? voucherRecipient?.order?.orderStatus[voucherRecipient?.order?.orderStatus.length - 1]?.status : "Not yet", // You need to fill this with appropriate data if available
          trackingLink: voucherRecipient?.order?.deliveryTrackingLink !== undefined ? voucherRecipient?.order?.deliveryTrackingLink : "Not yet",// You need to fill this with appropriate data if available
          latestUpdatedOn: voucherRecipient?.order?.orderStatus[voucherRecipient?.order?.orderStatus.length - 1]?.datetime !== undefined ? ConvertDateObjToHumanReadableDateString(voucherRecipient?.order?.orderStatus[voucherRecipient?.order?.orderStatus.length - 1]?.datetime) : "Not yet", // Convert createdAt to a human-readable format
        }));
        setTableData(userData);
        setFilteredTableData(userData);
        SET_VOUCHERS_REGISTERED_DATA(userData.length.toString());
        SET_VOUCHERS_CLAIMED_DATA(userData.filter((each) => (each.isClaimed === "true")).length.toString());
        SET_VOUCHERS_YET_TO_CLAIM_DATA(userData.filter((each) => (each.isClaimed !== "true")).length.toString());
        SET_VOUCHERS_GOT_DELIVERED_DATA(userData.filter((each) => (each.orderStatus === "DELIVERED")).length.toString());

      } else {
        toast.error(resp?.data.message);
      }
    } catch (error) {
      // Handle error
      console.log("Handled the error in try catch block");
      console.error(error);
    }
  };



  useEffect(() => {
    getVouchersByVoucherType();
  }, [])

  // DEMO DATA
  const DATA_categories = [
    {
      name: "New Arrivals",
    },
    {
      name: "Sale",
    },
    {
      name: "Backpacks",
    },
    {
      name: "Travel Bags",
    },
    {
      name: "Laptop Sleeves",
    },
    {
      name: "Organization",
    },
    {
      name: "Accessories",
    },
  ];

  const [showSearchForm, setShowSearchForm] = useState(false);
  const [categoriesState, setCategoriesState] = useState<string[]>([]);

  const renderMagnifyingGlassIcon = () => {
    return (
      <svg
        width={22}
        height={22}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 22L20 20"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  const renderSearchForm = () => {
    return (
      <div className="flex-1 w-full py-2 text-slate-900 dark:text-slate-100">
        <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1.5 px-5 h-full rounded">
          {renderMagnifyingGlassIcon()}
          <input
            type="text"
            placeholder="Search by name of the person"
            className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-base"
            autoFocus
            onChange={handleSearchChange}
            value={searchQuery}
          />
          {/* <button type="button" onClick={() => setShowSearchForm(false)}>
            <XMarkIcon className="w-5 h-5" />
          </button> */}
        </div>
      </div>
    );
  };

  const handleChangeCategories = (checked: boolean, name: string) => {
    checked
      ? setCategoriesState([...categoriesState, name])
      : setCategoriesState(categoriesState.filter((i) => i !== name));
  };
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderCard = (item: any) => {
    return (
      <VoucherDataCards
        name={item.name}
        desc={item.desc}
        // bgSVG={item.svgBg}
        // featuredImage={item.image}
        key={item.id}
        color={item.color}
        path={item?.path}
      />
    );
  };

  return (
    <div className={`container`}>
      <Helmet>
        <title>Hr Vouchers</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
          <Link to={"/hr-dashboard"} className="">
            My Menu
          </Link>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          <Link to={"/hr-vouchers"} className="">
            View Vouchers
          </Link>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          <span className="underline">{voucherData.voucherTypeName}</span>
        </div>
      </div>
      <div>
        <div>
          <Heading2 heading="Overview" subHeading=" " />
        </div>
        <div className="relative py-6 mb-12 lg:py-6">
          {/* <BackgroundSection /> */}
          <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>
            {data.map((item) => renderCard(item))}
          </div>
        </div>
        <div>
          <Heading2 className="lg:mb-3" heading="View details" subHeading=" " />
        </div>
        <div className="mt-6 rounded mb-24 lg:mt-4 border border-slate ">
          <div className="bg-white rounded ">
            <div className=" flex items-center justify-between pb-4 m-4 pt-4">
              <div className="flex w-full  mr-5 items-center">
                {renderSearchForm()}
                <span className="pl-4">
                </span>
              </div>
              <div className="">
                <ButtonPrimary onClick={() => convertToExcel(filteredTableData, "Voucher Status Data.xlsx")} type="button">
                  <svg
                    fill="#fff"
                    width="20px"
                    height="20px"
                    viewBox="0 0 1.2 1.2"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m0.65 0.715 0.157 -0.157a0.025 0.025 0 0 1 0.035 0.035l-0.2 0.2a0.025 0.025 0 0 1 -0.035 0l-0.2 -0.2a0.025 0.025 0 0 1 0.035 -0.035L0.6 0.715V0.175a0.025 0.025 0 1 1 0.05 0v0.54Zm0.35 0.06a0.025 0.025 0 1 1 0.05 0v0.15a0.125 0.125 0 0 1 -0.125 0.125h-0.6A0.125 0.125 0 0 1 0.2 0.925v-0.15a0.025 0.025 0 1 1 0.05 0v0.15A0.075 0.075 0 0 0 0.325 1h0.6a0.075 0.075 0 0 0 0.075 -0.075v-0.15Z" />
                  </svg> <span className="pl-2">

                    Export
                  </span>
                </ButtonPrimary>
              </div>
            </div>
            <DynamicTable isCLickable={false} path="/hr-vouchers-details" tableData={filteredTableData} tableHeader={tableHeaders} />
            <div className=" pb-4 m-4 pt-4 flex flex-col mt-12 lg:mt-4 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-end sm:items-center">
              {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherDetails;

import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ShippingAddressCart from "./ShippingAddressCart";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUserCartDetails,
  getUserAddresses,
  getAvailablePoints,
} from "network/services/account";
import {
  ADD_CART_ACTION,
  EMPTY_CART_ACTION,
  UPDATE_CART_ACTION,
} from "../../redux/actions/cart";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { SET_POINTS_ACTION } from "../../redux/actions/points";
import { placeEcommOrder, placeEcommOrderv2, placePointsOrder, placePointsOrderv2, verifyPayment } from "network/services/order";
import useRazorpay from "react-razorpay";
import CartProduct from "./CartProduct";
import { getLocalStorage } from "network/helper";
import { getCompanyID } from "network/services/company";
import SuccessModal from "./SuccessModal";

interface PointsOrderData {
  data: Array<any>,
  name: string,
  purchaseOrder: string,
  email: string,
  contactNo: string,
  altContact?: string,
  address: string,
  district?: string,
  city: string,
  state: string,
  country: string,
  pincode: string,
}

interface EcommOrderData {
  data: Array<any>,
  name: string,
  paymentGateway: string,
  email: string,
  contactNo: string,
  altContact?: string,
  address: string,
  district?: string,
  city: string,
  state: string,
  country: string,
  pincode: string,
}

const CartPage = () => {
  const [Razorpay] = useRazorpay()
  const navigate = useNavigate()

  const CartSelector = useSelector((state: any) => state.CART);
  const availablePoints = useSelector((state: any) => state?.Points)

  const dispatch = useDispatch();
  const [cart, setCart] = useState<Array<any>>([])
  const [enableClaimByPoints, setEnableClaimByPoints] = useState(false)
  const [claimByPoints, setClaimByPoints] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [sendData, setsendData] = useState<Object>({});
  const [OrderType, setOrderType] = useState<String>('');
  const [OrderId, setOrderId] = useState<String>('');


  const [showSuccess, setShowSuccess] = useState(false);

  interface AddressDetailsFromAPI {
    _id?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    altContact: string;
    address: string;
    district: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
  }

  const [savedAddresses, setSavedAddresses] = useState<AddressDetailsFromAPI[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<number>(-1)
  const [newAddress, setNewAddress] = useState<AddressDetailsFromAPI | null>(null)
  const [newAddressSelected, setNewAddressSelected] = useState<boolean>(false)
  const [totalGst, setTotalGst] = useState<number>(0)
  const [cartIsEmpty, setCartIsEmpty] = useState(false)
  const [shippingCharges, setShippingCharges] = useState<number>(0)
  const [userDetails, setUserDetails] = useState<any>()
  const [addressCopanyPresent, setaddressCopanyPresent] = useState<Boolean>()


  async function getSavedAddresses() {
    const savedAddresses = await getUserAddresses();
    const local = localStorage.getItem("company");
    const company = local ? JSON.parse(local) : null;
    if (company && company.pickupEnabled) {
      setaddressCopanyPresent(true);
      const user = localStorage.getItem("user_details");
      const userDetails = user ? JSON.parse(user) : null;
      if (userDetails) {
        company.addressDetails = company.addressDetails.map((address: any) => ({
          firstName: userDetails ? userDetails.name : "",
          lastName: "XXX",
          email: "",
          phoneNo: "9999999999",
          altContact: "9999999999",
          address: address.address || "",
          district: address.district || "",
          city: address.city || "",
          state: address.state || "",
          country: address.country || "",
          pincode: address.postalcode || ""
        }));
        console.log(company.addressDetails, "transformed company.addressDetails");
        setSavedAddresses(company.addressDetails);
      }
    } else {
      setaddressCopanyPresent(false);
      setSavedAddresses(savedAddresses.data);
    }
  }

  useEffect(() => {
    getSavedAddresses();
    const company = getLocalStorage('company')
    if (!company.isEcommerceEnabled) {
      navigate("/home")
    }
    if (company.shippingCharges)
      setShippingCharges(company.shippingCharges)

    const user = getLocalStorage("user_details")
    setUserDetails(user);

  }, []);

  function calculateTotal(): { subtotal: number, gst: number } {
    let total: number = 0;
    let gst: number = 0
    for (const item of CartSelector) {
      total += item.quantity * (item?.product.currentPrice - item.product.gstAmount);
      gst += item.quantity * item?.product.gstAmount;
    }
    return {
      subtotal: total,
      gst
    };
  }

  const getCartDetailsFromAPI = async () => {
    try {
      const response = await getUserCartDetails();
      if (response?.data) {
        const temp = response.data.find((item: any) => !item.product.isEnabledForPoints)
        if (!temp) setEnableClaimByPoints(true)
        else setEnableClaimByPoints(false)
        dispatch(ADD_CART_ACTION(response.data));
        setCart(response.data)
        if (Array.isArray(response.data) && response.data.length == 0) setCartIsEmpty(true)
      }
    } catch (error) { }
  };

  const fetchAvailablePoints = async () => {
    const res = await getAvailablePoints()
    if (res.data && res.data.totalPoints)
      dispatch(SET_POINTS_ACTION(res.data.totalPoints))
    else dispatch(SET_POINTS_ACTION(0))
  }

  useEffect(() => {
    getCartDetailsFromAPI();
    fetchAvailablePoints();
  }, []);

  useEffect(() => {
    console.log(CartSelector)
    const prices = calculateTotal()
    setTotalGst(prices.gst)
    setSubTotal(prices.subtotal)
    const temp = CartSelector.find((item: any) => !item.product.isEnabledForPoints)
    if (!temp) setEnableClaimByPoints(true)
    else setEnableClaimByPoints(false)
    if (CartSelector.length === 0) setCartIsEmpty(true)
    else setCartIsEmpty(false)
  }, [CartSelector])

  const handleNewAddressCreation = () => {
    setNewAddress(() => ({
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      altContact: "",
      address: "",
      district: "",
      city: "",
      state: "",
      country: "India",
      pincode: "",
    }))
    setNewAddressSelected(false)
    setSelectedAddress(-1)
  }

  const handleCancelNewAddress = () => {
    setNewAddressSelected(false)
    setNewAddress(null)
  }
  const [company, setcompany] = useState([]);

  const initCompanyDetails = async () => {
    let obj = {
      hostUrl:
        String(window.location.host).includes("localhost:3000")
          ? process.env.REACT_APP_DEFAULT_COMPANY_URL
          : String(window.location.host).split('/')[0],
    };
    const resp = await getCompanyID(obj);
    if (resp?.data?._id) {
      const company = resp.data;
      console.log(company, "company")
      setcompany(company);
    }
  };


  const handleNewAddressAdd = async () => {
    initCompanyDetails();
    const savedAddresses = await getUserAddresses();
    setSavedAddresses(savedAddresses.data);
    toast.success("Address saved successfully")
    setNewAddress(null)
    setNewAddressSelected(false)
    setSelectedAddress(savedAddresses.data.length - 1)
  }

  const handlePointsOrder = async () => {
    const source = (newAddressSelected && newAddress != null) ? newAddress : savedAddresses[selectedAddress]

    const pointsOrderDetails: PointsOrderData = {
      data: CartSelector.map((item: any) => item._id),
      name: source.firstName + " " + source.lastName,
      purchaseOrder: "null",
      email: userDetails.email,
      contactNo: source.phoneNo,
      address: source.address,
      city: source.city,
      state: source.state,
      country: source.country,
      pincode: source.pincode,
    }

    if (source.altContact) pointsOrderDetails.altContact = source.altContact
    if (source.district) pointsOrderDetails.district = source.district;

    const res = await placePointsOrderv2(pointsOrderDetails);

    console.log(res, "Response");
    if (res.errRes) {
      toast.error(res.errRes.data.message)
      return
    }
    setsendData(res.data);
    setOrderType('point');
    setOrderId(res.data._id);
    setShowSuccess(true)
    dispatch(EMPTY_CART_ACTION())
    fetchAvailablePoints()
  }

  const razorpayResponseHandler = async (response: any, resp: any, type: string) => {
    const res = await verifyPayment({
      paymentGateway: "RAZORPAY",
      paymentId: response.razorpay_payment_id,
      orderId: resp.data.order._id,
    })

    if (res.data) {
      setShowSuccess(true);
      setsendData(resp.data.order);
      setOrderId(resp.data.order._id);
      setOrderType('ecommerce');
      dispatch(EMPTY_CART_ACTION())
    }

    else {
      toast.error("Order Could Not Be Confirmed")
    }
  }

  const handleEcommOrder = async () => {
    const source = (newAddressSelected && newAddress != null) ? newAddress : savedAddresses[selectedAddress]
    console.log(source, "source");
    const ecommOrderDetails: EcommOrderData = {
      data: CartSelector.map((item: any) => item._id),
      paymentGateway: "RAZORPAY",
      name: source.firstName + " " + source.lastName,
      email: userDetails.email,
      contactNo: source.phoneNo,
      address: source.address,
      city: source.city,
      state: source.state,
      country: source.country,
      pincode: source.pincode
    }

    if (source.altContact) ecommOrderDetails.altContact = source.altContact
    if (source.district) ecommOrderDetails.district = source.district;
    console.log(ecommOrderDetails, "ecommOrderDetails")
    const res: any = await placeEcommOrderv2(ecommOrderDetails);

    console.log(res, "Before redirecting");

    if (res.errRes) {
      toast.error(res.errRes.data.message)
      return
    }

    const checkoutOptions = {
      key: res.data.payment.razorpayId,
      amount: res.data.payment.paymentDetails.amount,
      currency: res.data.payment.currency,
      order_id: res.data.payment.paymentDetails.id,
      name: "Giftlinks",
      handler: (response: any) => razorpayResponseHandler(response, "ecommerce", res.data.order._id)
    }
    const rzp1 = new Razorpay(checkoutOptions)
    rzp1.open()

  }

  const handlePayment = () => {
    if (selectedAddress == -1 && newAddress == null) {
      toast.error("Please select an address")
      return
    }
    const date: Date = new Date();
    const timeWithAmPm: string = formatTimeWithAmPm(date);

    if (claimByPoints) handlePointsOrder()
    else handleEcommOrder()
  }


  function formatTimeWithAmPm(date: Date): string {
    let hours: number = date.getHours();
    let minutes: number = date.getMinutes();
    const ampm: string = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr: string = minutes < 10 ? '0' + minutes : minutes.toString(); // add leading zero to minutes if needed

    const strTime: string = hours + ':' + minutesStr + ' ' + ampm;
    return strTime;
  }

  const renderLeft = () => {
    return (
      <div className="space-y-8 mt-8">
        {
          newAddress != null && (
            <div id="ShippingAddress" className="scroll-mt-24">
              <ShippingAddressCart
                newAddress
                data={newAddress}
                isSelected={newAddressSelected}
                selectAddress={() => {
                  toast.success("Address selected")
                  setNewAddressSelected(true)
                }}
                cancelNewAddress={handleCancelNewAddress}
                onNewAddressAdded={handleNewAddressAdd}
                setNewAddress={(address: any) => setNewAddress(address)}
              />
            </div>
          )
        }
        {
          savedAddresses.map((address, index) => {
            return (
              <div key={index} id="ShippingAddress" className="scroll-mt-24">
                <ShippingAddressCart
                  data={address}
                  isSelected={index == selectedAddress}
                  selectAddress={() => {
                    toast.success("Address selected")
                    setSelectedAddress(index)
                  }}
                />
              </div>
            )
          })
        }
      </div>
    );
  };

  const deleteFromCart = (index: number) => {
    setCart((state) => {
      return [...state.slice(0, index), ...state.slice(index + 1)]
    })
    if (CartSelector.length == 1) setCartIsEmpty(true)
  }

  return (!cartIsEmpty) ? (
    <div className="nc-CartPage">
      <SuccessModal
        successMessage="Order Placed Successfully"
        redirectMessage="Redirection to orders page..."
        showModal={showSuccess}
        state={{ state: sendData }}
        type={`${OrderType}`}
        OrderId={`${OrderId}`}
      />
      <Helmet>
        <title>Shopping Cart</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="mb-12 sm:mb-16">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Shopping Cart
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={"/#"} className="">
              Homepage
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">Shopping Cart</span>
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-[50%] xl:w-[45%] divide-y divide-slate-200 dark:divide-slate-700 ">
            {CartSelector && CartSelector.map((item: any, index: number) => (
              <CartProduct item={item} index={index} key={index} deleteFromCart={deleteFromCart} />
            ))}
          </div>
          <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
          <div className="flex-1">
            <div className="sticky top-28">
              <div className="flex flex-row justify-between ">
                {addressCopanyPresent ? (
                  <>
                    <h3 className="text-lg font-semibold ">Select your address</h3>
                    <button className="underline" >Select Address</button>
                  </>
                ) :
                  <>
                    <h3 className="text-lg font-semibold ">Select your address</h3>
                    <button className="underline" onClick={handleNewAddressCreation}>Add Address</button>

                  </>
                }
              </div>
              {renderLeft()}
              <h3 className="text-lg font-semibold mt-8">Order Summary</h3>
              <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                <div className="flex justify-between py-2 mt-2">
                  <span>Subtotal</span>
                  <span className="font-semibold text-slate-900 dark:text-slate-200">
                    ₹ {subTotal.toFixed(2)}
                  </span>
                </div>
                {
                  !claimByPoints && (
                    <div className="flex justify-between py-2 mt-2">
                      <span>GST</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200">
                        ₹ {totalGst.toFixed(2)}
                      </span>
                    </div>
                  )
                }
                {
                  !claimByPoints && (
                    <div className="flex justify-between py-2">
                      <span>Shipping Charges</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200">
                        ₹ {shippingCharges.toFixed(2)}
                      </span>
                    </div>
                  )
                }
                {
                  enableClaimByPoints && availablePoints > subTotal && (
                    <div>
                      {claimByPoints && (
                        <div className="flex justify-between py-4">
                          <span>Claimable Points<br /><span onClick={() => setClaimByPoints(!claimByPoints)} className="text-red-500 cursor-pointer">Remove</span></span>
                          <span className="font-semibold text-slate-900 dark:text-slate-200">
                            - ₹ {(subTotal).toFixed(2)}
                          </span>
                        </div>
                      )}

                      {claimByPoints == false && (
                        <div className="flex justify-between py-4">
                          <span>
                            <b>Kudos!, you have {availablePoints} points in your wallet</b><br />
                            <span onClick={() => setClaimByPoints(!claimByPoints)} className="text-green-800 cursor-pointer"><b>Claim Now</b></span>
                          </span>
                        </div>
                      )}
                    </div>
                  )
                }
                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                  <span>Order total</span>
                  <span>₹ {(claimByPoints ? 0 : subTotal + totalGst + shippingCharges).toFixed(2)}</span>
                </div>
              </div>
              <ButtonPrimary onClick={handlePayment} className="mt-8 w-full">
                Confirm Order
              </ButtonPrimary>
              <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                <p className="block relative pl-5">
                  <svg
                    className="w-4 h-4 absolute -left-1 top-0.5"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 8V13"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.9945 16H12.0035"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Learn more{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="##"
                    className="text-slate-900 dark:text-slate-200 underline font-medium pointer-events-none"
                  >
                    Shipping
                  </a>
                  {` `} infomation
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <div className="text-2xl text-gray-400 font-semibold text-center min-h-[60vh] flex flex-col justify-center">
      <h2>
        Cart Is Empty
      </h2>
      <SuccessModal
        successMessage="Order Placed Successfully"
        redirectMessage="Redirection to orders page..."
        showModal={showSuccess}
        state={sendData}
        type={`${OrderType}`}
        OrderId={`${OrderId}`}
      />
    </div>
  )
};

export default CartPage;

import React, { FC, useEffect, useState } from "react";
import Button from "shared/Button/Button";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";

interface Address {
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    address: string;
    district: string;
    city: string;
    state: string;
    country: string;
    altContact: string;
    pincode: string;
    _id: string;
}

interface Data {
    category: string;
    selected: { product: string; entity: string | null };
    products: Array<any>;
}

interface OrderConfirmationProps {
    show: boolean;
    closePopup: () => void;
    address: Address;
    productImage?: string;
    data: Data[];
    onOrderConfirm: () => void;
}

interface Product {
    id: string;
    name: string;
    description: string;
    images: string[];
}

const OrderConfirmation: FC<OrderConfirmationProps> = ({ show, closePopup, address, data, onOrderConfirm }) => {
    const [selectedProductDetails, setSelectedProductDetails] = useState<Product | null>(null);

    const fetchProductDetailsFromData = (productId: string) => {
        for (let item of data) {
            const product = item.products.find((product) => product._id === productId);
            if (product) {
                return product;
            }
        }
        return null;
    };

    useEffect(() => {
        if (data.length > 0 && data[0].selected.product) {
            const productDetails = fetchProductDetailsFromData(data[0].selected.product);
            console.log(productDetails, "productDetails");
            if (productDetails) {
                setSelectedProductDetails(productDetails);
                console.log("Product Details from Data:", productDetails);
            }
            { console.log(selectedProductDetails) }

        }
    }, [data]);

    const handleOrderConfirm = () => {
        onOrderConfirm();
        closePopup();
    };

    return (
        <div className={`${show ? "block" : "hidden"} fixed top-0 left-0 w-screen h-screen z-[40] overflow-hidden`}>
            <div onClick={closePopup} className="absolute top-0 left-0 w-screen h-screen bg-black/50 z-[-10]"></div>

            <div className="w-full h-full lg:w-[80vw] lg:max-w-[1200px] lg:max-h-[90vh] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 bg-white p-6 lg:rounded-xl">
                <ButtonClose onClick={closePopup} className="absolute right-3 top-3" />
                <div className="nc-ProductQuickView overflow-y-auto overflow-hidden h-full w-full no-scrollbar">
                    <div className="lg:flex h-full">
                        <div className=" overflow-y-auto h-100 w-full lg:w-[50%] flex items-start justify-start p-4 lg:border-r border-gray-200">
                            <div className="relative w-full h-[300px]">
                                {selectedProductDetails?.images[0] ? (
                                    <>
                                        <div className="">
                                            <h2 className="text-2xl font-semibold text-gray-800 ">{selectedProductDetails?.name}</h2>
                                            < img src={selectedProductDetails?.images[0]} alt="Product" className="w-full h-full object-cover rounded-xl my-4" />
                                        </div>
                                    </>
                                ) : (
                                    <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-xl">
                                        <span className="text-xl text-gray-500">No Image Available</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="w-full lg:w-[50%] pt-6 lg:pt-5 lg:pl-7 xl:pl-8 flex flex-col justify-between overflow-y-auto">
                            <div className="flex-1 space-y-4">
                                <div>
                                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Check Your Order Details</h2>
                                    <hr className="border-slate-200 dark:border-slate-700 mb-4" />
                                </div>
                                <div className="space-y-2 text-left border border-gray-200 p-4 rounded-md">
                                    <h3 className="text-md font-semibold mb-2">Shipping Address</h3>
                                    <p><strong>Full Name:</strong> {address.firstName} {address.lastName}</p>
                                    <p><strong>Email:</strong> {address.email}</p>
                                    <p><strong>Phone:</strong> {address.phoneNo}</p>
                                    {address.altContact && <p><strong>Alternate Phone:</strong> {address.altContact}</p>}
                                    <p><strong>Address:</strong> {address.address}</p>
                                    {address.district && <p><strong>District:</strong> {address.district}</p>}
                                    <p><strong>City:</strong> {address.city}</p>
                                    <p><strong>State:</strong> {address.state}</p>
                                    <p><strong>Country:</strong> {address.country}</p>
                                    <p><strong>Pincode:</strong> {address.pincode}</p>
                                </div>
                                <div>
                                    <p className="text-gray-500">
                                        Please take a moment to review your order. Unfortunately, we cannot process item exchanges or changes after your order has been placed.
                                    </p>
                                </div>
                            </div>

                            <div className="flex justify-between items-center my-2">
                                <Button onClick={closePopup} className="bg-gray-200">
                                    Update
                                </Button>
                                <ButtonPrimary onClick={handleOrderConfirm}>
                                    Confirm Order
                                </ButtonPrimary>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderConfirmation;
